import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpg";
import review2 from "../../assets/review2.jpg";
import review3 from "../../assets/review3.jpg";
import review4 from "../../assets/review4.jpg";
import review5 from "../../assets/review5.jpg";
import review6 from "../../assets/review6.jpg";
import like from "../../assets/like.svg";
import user from "../../assets/user.png";
import { useState } from "react";

export const Reviews = () => {
  const [showReview, setShowReview] = useState(false);
  return (
    <>
      <p className="reviews__title">Рейтинг и отзывы</p>
      <div className="reviews__wrapper">
        <div>
          <p className="reviews__score">4.8</p>
          <div className="star__wrapper">
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGrey} alt="grey star" className="green__star" />
          </div>
          <p className="user__wrapper">
            Общий
            <img src={user} alt="user icon" width={16} height={16} />
          </p>
          <p className="reviews__number">2697</p>
        </div>
        <div>
          <ul className="lines__list">
            <li className="lines__item">
              <p className="numbers">5</p>
              <div className="first__line desktop_one">
                <div className="first__line--green"></div>
              </div>
            </li>
            <li className="lines__item">
              <p className="numbers">4</p>
              <div className="first__line desktop_two">
                <div className="second__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">3</p>
              <div className="first__line desktop_three">
                <div className="third__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">2</p>
              <div className="first__line desktop_four">
                <div className="fourth__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">1</p>
              <div className="first__line desktop_five">
                <div className="fourth__line--green"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review1}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Азамат</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">29.09.2024</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            Мен көрген ең жақсы жоба! 20к бонус бірден түсіп, ұяшықтарға ұшып
            кетті және қатарынан 3 бонус түсті !! Әжелерді картаға бірден
            әкелді! Керемет жоба, мен жиі ойнаймын!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Казино</p>
            <p className="review__description">
              Азамат, пікіріңізге рахмет! Клиенттеріміздің жобамыздан ләззат
              алуы біз үшін өте маңызды. Біздің команда сіз біздің өнімді
              пайдаланудан ләззат алуыңыз үшін бәрін жасайды!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review2}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Адиль</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">28.09.2024</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            Казино тағы да аналогы жоқ бомба жобасын жасады. Бәсекелестердің
            ішіндегі ең жақсысы. Керемет слоттар, ақылсыз бонустар және Құдай
            сізді жарылқасын. Мен қызық үшін 1500 теңге толтырдым, банк
            картасына 9300 шығардым! Керемет жоба, ол нарықты жарып жіберетініне
            сенімдімін!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Казино</p>
            <p className="review__description">
              Әділ, оң пікіріңізге рахмет. Біз өз жобамызды үнемі жетілдіру
              үстіндеміз, сізге ұнағанына өте қуаныштымыз!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review3}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Булат</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGrey}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">27.09.2024</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            Мен әдетте осы казинолардың барлығына сенбеймін! Бірақ бұл қолданба
            жай ғана нәрсе! 2700 теңге лақтырып, 15 минутта 13200 теңге ұттым.
            Мен ойнауды және ақшаны қысқартуды жалғастырамын!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            {/* <p className="review__title">Казино</p> */}
            <p className="review__description">
              Булат, жақсы пікір үшін рахмет! Ойнаңыз және ләззат алыңыз!
            </p>
          </div>
        </div>
      </div>
      {showReview && (
        <>
          <div className="item__wrapper">
            <img
              src={review4}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Рустам</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">26.09.2024</p>
                </div>
                <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div>
              </div>
              <p className="item__text">
                Жігіттерден мұндай керемет жоба жасайды деп күтпедім! Құрмет пен
                құрмет! Менің өмірімдегі ең жомарт және жомарт казино! Мен таң
                қалдым)
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                {/* <p className="review__title">Казино</p> */}
                <p className="review__description">
                  Рустам, пікіріңізге рахмет. Біздің жоба адамдарға эмоциялар
                  беру үшін ғана жасалған. Біз сізді бақытты ету үшін бар
                  күшімізді саламыз! Сізге сәттілік!
                </p>
              </div>
            </div>
          </div>
          {/* <div className="item__wrapper">
            <img
              src={review5}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Максим Ефремов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">08.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                Насыпало за 5 дней игры очень круто! Спасибо за офигенное
                Casino!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Casino</p>
                <p className="review__description">
                  Максим Ефремов, спасибо за отзыв! Нам очень важно, чтобы наши
                  клиенты получали удовольствие от нашего проекта.
                </p>
              </div>
            </div>
          </div>
          <div className="item__wrapper">
            <img
              src={review6}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Олег Мартынов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">05.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                У меня нет слов. С 500 рублей поднял 7000 за пару часов ;))
                Давно мне так не фартило. Лучшее Casino в которое я играл!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Casino</p>
                <p className="review__description">
                  Олег Мартынов, благодарим вас за положительный отзыв.
                  Стараемся удовлетворять наших клиентов!
                </p>
              </div>
            </div>
          </div> */}
        </>
      )}
      <button
        type="button"
        className="reviews__button"
        onClick={() => {
          setShowReview((prevState) => !prevState);
        }}
      >
        {showReview ? "Скрыть отзывы" : "Все отзывы"}
      </button>
    </>
  );
};
